import React from "react";
import styled from "@emotion/styled";

import mediaqueries from "@styles/media";

import { Icon } from '@types';

const Logo: Icon = ({ fill = "white" }) => {
  return (
    <LogoContainer>
        <svg width="75px" height="45px" viewBox="0 0 75 45">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Logo" fill={fill} fillRule="nonzero">
                    <path d="M15.1115038,0 L15.1080962,0.00102241706 C15.0032744,0.0329082227 12.5180019,0.819248033 12.9213288,3.57951839 L17.1344954,11.4194406 L17.1792702,11.4925632 L17.2357805,11.6073915 L18.609537,14.1635604 L30.5414866,14.1635604 L30.5414866,6.1507527 C30.5414866,4.54591301 29.3161368,3.21794701 29.0738473,2.97065303 C27.6979239,1.75384088 25.8300193,0.574692441 23.3829307,0 L36.3466872,0 L36.3466872,45 L24.8144613,45 L24.8180155,44.9965002 C24.9329355,44.8821354 27.776316,41.962732 24.8144613,35.9348142 L8.74842332,6.79820033 L8.7453605,6.79186493 C8.64516218,6.5861181 6.07941029,1.42816223 0,0 L15.1115038,0 Z M51.6170693,0 C49.1701611,0.574692441 47.3020761,1.75384088 45.9261527,2.97065303 C45.6838632,3.21794701 44.4585134,4.54591301 44.4585134,6.1507527 L44.4585134,14.1635604 L56.3906435,14.1635604 L57.7644002,11.6073915 L57.8207298,11.4925632 L57.8655046,11.4194406 L62.0786712,3.57951839 C62.4906718,0.759887488 59.8884962,0 59.8884962,0 L75,0 C68.7965202,1.45730836 66.2515767,6.79820033 66.2515767,6.79820033 L50.1853582,35.9348142 C47.163238,42.0857507 50.1853582,45 50.1853582,45 L38.6534935,45 L38.6534935,0 L51.6170693,0 Z M30.5414866,18.9393136 L21.1757845,18.9393136 L29.6496008,34.7062438 C29.8936958,34.881885 30.1937595,34.9860572 30.5414866,34.8822525 L30.5414866,18.9393136 Z M53.8242155,18.9393136 L44.4585134,18.9393136 L44.4585134,34.8822525 C44.8062405,34.9860572 45.1063042,34.881885 45.3505796,34.7062438 L53.8242155,18.9393136 Z" transform="translate(37.500000, 22.500000) scale(-1, 1) rotate(-180.000000) translate(-37.500000, -22.500000) "></path>
                </g>
            </g>
        </svg>
    </LogoContainer>
  );
};

export default Logo;

const LogoContainer = styled.div`
  .Logo__Mobile {
    display: none;
  }

  ${mediaqueries.tablet`
    .Logo__Desktop {
      display: none;
    }
    
    .Logo__Mobile{
      display: block;
    }
  `}
`;