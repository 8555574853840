import React from "react";

import { Icon } from '@types';

const EmailIcon: Icon = ({ fill = "white", ...props }) => (
<svg width="14px" height="14px" viewBox="0 0 14 14">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path d="M7,0.000293184499 C3.17511201,-0.0348634241 0.0439016149,3.09621583 0,7 C0.0439016149,10.9037842 3.17511201,14.0348634 7,13.9997068 C8.40615766,14.0045913 9.78169768,13.5806937 10.9502471,12.7823665 C11.1713999,12.6309078 11.2965863,12.3705338 11.2786498,12.0993249 C11.2607132,11.8281161 11.1023787,11.5872754 10.8632894,11.4675253 C10.6242,11.3477752 10.3406791,11.3673085 10.1195263,11.5187673 C9.19659802,12.1487116 8.11027378,12.4827597 7,12.4780314 C3.99820527,12.513914 1.53407135,10.0636835 1.49088431,7 C1.53407135,3.93631646 3.99820527,1.48608601 7,1.52196858 C10.0017947,1.48608601 12.4659286,3.93631646 12.5091157,7 L12.5091157,7.54232511 C12.4890512,8.18038436 11.9764668,8.68703296 11.3509968,8.68703296 C10.7255267,8.68703296 10.2129424,8.18038436 10.1928778,7.54232511 L10.1928778,7 C10.2156406,5.67666973 9.45210904,4.47067727 8.26069896,3.94813782 C7.06928887,3.42559837 5.68657871,3.69027264 4.76163821,4.61791763 C3.83669772,5.54556262 3.55319243,6.95197576 4.04420498,8.17696068 C4.53521753,9.40194561 5.7032475,10.2022569 7,10.2022137 C7.86775325,10.2023952 8.6985164,9.84354971 9.30252172,9.20764666 C10.0052198,10.0872426 11.1716719,10.4247212 12.222083,10.0523358 C13.2724942,9.67995037 13.9828252,8.67712601 14,7.54232511 L14,7 C13.9560984,3.09621583 10.824888,-0.0348634241 7,0.000293184499 Z M7,8.68053831 C6.09749512,8.65018374 5.38705893,7.88408445 5.40694238,6.96266368 C5.42682584,6.04124292 6.1696206,5.30781178 7.07255972,5.31804301 C7.97549883,5.32827424 8.70215727,6.07835579 8.70199355,7 C8.68533639,7.94317236 7.92413204,8.69478102 7,8.68053831 L7,8.68053831 Z" id="Email" fill={fill} fillRule="nonzero"></path>
    </g>
</svg>
);

export default EmailIcon;
